.kubeapps-header {
  width: 100%;
  height: 4rem;
  padding-top: 0.3rem;
  border-bottom: 2px solid #f1f1f1;
  background-color: white; }
  .kubeapps-header img {
    max-height: 3rem;
    margin: 0.2rem 0 0.2rem 0.6rem; }
  .kubeapps-header .kubeapps-header-content {
    width: 100%; }
    .kubeapps-header .kubeapps-header-content .kubeapps-title-section {
      display: flex;
      min-height: 60px; }
      .kubeapps-header .kubeapps-header-content .kubeapps-title-section .kubeapps-title-block {
        display: flex;
        flex-direction: column;
        justify-content: center; }
        .kubeapps-header .kubeapps-header-content .kubeapps-title-section .kubeapps-title-block h1 {
          margin: 0;
          margin-left: 0.6rem;
          padding-top: 0.2rem; }
        .kubeapps-header .kubeapps-header-content .kubeapps-title-section .kubeapps-title-block h3 {
          margin-left: 1rem;
          font-weight: 500;
          margin-top: 0; }
        .kubeapps-header .kubeapps-header-content .kubeapps-title-section .kubeapps-title-block .kubeapps-header-subtitle {
          margin-left: 1rem; }
          .kubeapps-header .kubeapps-header-content .kubeapps-title-section .kubeapps-title-block .kubeapps-header-subtitle img {
            max-height: 1rem;
            padding-right: 0.2rem;
            margin: 0;
            max-width: 30px; }
          .kubeapps-header .kubeapps-header-content .kubeapps-title-section .kubeapps-title-block .kubeapps-header-subtitle span {
            color: var(--clr-p1-color, #666666);
            padding-top: 0.1rem; }
    .kubeapps-header .kubeapps-header-content .control-buttons {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 100%; }
      .kubeapps-header .kubeapps-header-content .control-buttons .header-button {
        margin-left: 0.5rem; }

.header-version-label {
  color: var(--clr-h3-color, black);
  font-weight: 500;
  margin-right: 0.2rem;
  margin-right: 0.6rem; }
