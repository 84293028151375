.select-repo-form {
  display: grid;
  justify-content: center;
  margin-top: 5rem; }
  .select-repo-form-label {
    font-weight: 600;
    margin-bottom: 0.2rem;
    display: block; }
  .select-repo-form .clr-page-size-select {
    width: 100%; }
