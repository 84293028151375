.kubeapps__logo {
  max-width: 10em; }

.kubeapps-nav-link {
  outline: none !important;
  background-color: transparent;
  border: none;
  cursor: pointer; }
  .kubeapps-nav-link::before {
    display: inline-block;
    position: absolute;
    content: "";
    background: #fafafa;
    background: var(--clr-header-font-color, #fafafa);
    opacity: var(--clr-header-divider-opacity, 0.15);
    opacity: 0.15;
    height: 2rem;
    width: 0.05rem;
    width: var(--clr-global-borderwidth, 0.05rem);
    top: 0.5rem;
    left: 0; }
  .kubeapps-nav-link:hover::before {
    display: inline-block;
    position: absolute;
    content: "";
    background: #fafafa;
    background: var(--clr-header-font-color, #fafafa);
    opacity: var(--clr-header-divider-opacity, 0.15);
    opacity: 0.15;
    height: 2rem;
    width: 0.05rem;
    width: var(--clr-global-borderwidth, 0.05rem);
    top: 0.5rem;
    opacity: 0; }

.nav-link.active::after {
  display: inline-block;
  position: absolute;
  content: "";
  background: #fafafa;
  background: var(--clr-header-font-color, #fafafa);
  opacity: var(--clr-header-divider-opacity, 0.15);
  opacity: 0.15;
  height: 2rem;
  width: 0.05rem;
  width: var(--clr-global-borderwidth, 0.05rem);
  top: 0.5rem;
  opacity: 0; }

.nav-link::after {
  display: inline-block;
  position: absolute;
  content: "";
  background: #fafafa;
  background: var(--clr-header-font-color, #fafafa);
  opacity: var(--clr-header-divider-opacity, 0.15);
  opacity: 0.15;
  height: 2rem;
  width: 0.05rem;
  width: var(--clr-global-borderwidth, 0.05rem);
  top: 0.5rem;
  right: 0; }

.kubeapps-align-center {
  display: flex;
  align-items: center; }

.header {
  z-index: 1016; }

.left-menu {
  margin-top: 1.2rem;
  overflow-x: auto; }
  .left-menu-subsection {
    border-bottom: 2px solid #f1f1f1;
    margin: 0.6rem;
    padding-bottom: 0.6rem; }
    .left-menu-subsection-title {
      font-weight: 600;
      margin: 0 0 0.2rem 0; }
