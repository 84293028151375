.secret-datum-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-left: 0.3rem;
  overflow-x: auto;
  overflow-y: hidden; }

.secret-datum-icon {
  background: transparent;
  border: none;
  cursor: pointer; }
  .secret-datum-icon cds-icon {
    --color: var(--clr-link-color, #0072a3); }
    .secret-datum-icon cds-icon:hover {
      --color: var(--clr-link-active-color, #9e57bc); }
  .secret-datum-icon:focus {
    outline: none; }

.secret-datum-content {
  border-bottom: 1px solid var(--clr-forms-border-color, #b3b3b3) !important; }
