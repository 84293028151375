.tooltip {
  background: none;
  border: 0;
  padding: 0 0.1rem;

  &-content {
    visibility: hidden !important;
  }

  &-open {
    .tooltip-content {
      visibility: visible !important;
    }
  }
}
