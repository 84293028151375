.application-status-pie-chart {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-bottom: -50px; }
  .application-status-pie-chart-title {
    margin-top: 1.2rem;
    margin-bottom: 0.6rem;
    font-weight: 600; }
  .application-status-pie-chart-label {
    position: relative;
    bottom: 3.6rem; }
  .application-status-pie-chart-number {
    margin: 0;
    font-size: 1.5em; }
  .application-status-pie-chart-text {
    margin: -5px 0 0 0; }

.application-status-table {
  text-align: left;
  border-spacing: 1em 0em; }
  .application-status-table th {
    text-transform: uppercase; }
