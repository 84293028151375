.color-icon-info cds-icon {
  --color: var(--clr-alert-info-icon-color, #0072a3); }

.color-icon-success cds-icon {
  --color: var(--clr-alert-success-icon-color, #3c8500); }

.color-icon-warning cds-icon {
  --color: var(--clr-alert-warning-icon-color, #666666); }

.color-icon-danger cds-icon {
  --color: var(--clr-alert-danger-icon-color, #c21d00); }

.center {
  display: grid;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center; }

.notification-icon cds-icon {
  --color: hsl(48, 95%, 48%);
  width: 7px;
  margin-left: 3px; }

.layout {
  display: flex;
  min-height: 100vh;
  width: 100%;
  overflow-x: hidden;
  flex-direction: column; }

.kubeapps-main-container {
  display: flex;
  flex: 1 1 auto;
  min-height: 1px;
  height: calc(100vh - 3rem); }
  .kubeapps-main-container .content-area {
    flex: 1 1 auto;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden;
    padding: 0 0.6rem 0 0.6rem; }

.layout > main {
  flex: 1; }

.deployment-form-tabs {
  margin-bottom: 0.6rem; }
  .deployment-form-tabs-data {
    margin-top: 0.6rem;
    overflow-x: hidden; }

.deployment-form-control-buttons {
  margin-bottom: 0.6rem; }
  .deployment-form-control-buttons cds-button {
    margin-right: 0.6rem; }

.filters-menu {
  padding-left: 1.2rem; }
  .filters-menu h5 {
    text-transform: uppercase;
    font-size: small;
    font-weight: 500; }
    .filters-menu h5 cds-button {
      margin-top: -10px;
      padding-top: 10px;
      outline: 0; }
  .filters-menu .filter-section {
    font-weight: 500;
    margin: 0.7rem 0 0.5rem 0; }

.section-not-found {
  display: grid;
  justify-content: center;
  margin-top: 1.8rem;
  height: calc(100vh - 12rem);
  text-align: center; }
  .section-not-found .section-description {
    text-align: left; }
  .section-not-found img {
    max-width: 400px; }

.terminal-wrapper {
  background: var(--clr-color-neutral-100);
  color: var(--clr-color-on-neutral-100); }
  .terminal-wrapper .terminal-code {
    padding: 0.6rem;
    white-space: pre-wrap;
    text-align: left; }

.application-readme pre {
  margin: 0.625em 0;
  border-radius: 5px;
  overflow: auto;
  padding: 0.6rem;
  background: var(--clr-color-neutral-1000);
  color: var(--clr-color-on-neutral-1000); }

.application-readme ul {
  margin-top: 0.6rem; }
  .application-readme ul li p {
    display: inline; }

.application-readme blockquote {
  margin: 1em 0 1em 0;
  border-radius: 5px;
  border: 1px solid #ddd;
  background-color: #f1f1f1;
  padding: 0.1em 1em;
  max-width: 100%; }
  .application-readme blockquote p:first-child {
    margin-top: 0.6rem; }
  .application-readme blockquote p:last-child {
    margin-bottom: 0.6rem; }

.application-readme blockquote:before,
.application-readme blockquote:after {
  background-image: none; }

.application-readme img {
  max-width: 100%; }

.after-readme-button {
  padding: 1.2rem 0.6rem 1.2rem 0;
  display: flex;
  justify-content: flex-end;
  width: 100%; }

.appview-separator {
  border-bottom: 2px solid #f1f1f1;
  padding-bottom: 1.2rem; }

.appview-first-row {
  display: flex; }
  .appview-first-row section {
    margin-right: 2rem; }

.section-title {
  font-weight: 600; }

.filter-summary {
  padding: 1.2rem 0 0 1.2rem; }
  .filter-summary cds-icon {
    cursor: pointer; }

.margin-b-sm {
  margin-bottom: 0.3rem; }
