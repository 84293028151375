.tabs {
  max-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  .tab-button {
    &:focus {
      outline: none;
    }
  }
}
