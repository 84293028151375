.login-wrapper {
  width: calc(100vw + 0.6rem); }
  .login-wrapper .login {
    min-height: calc(100vh - 3rem); }
    .login-wrapper .login .login-group {
      padding: 0 0 0 0; }
    .login-wrapper .login .error.active {
      font-size: small; }

.login-submit-button {
  display: grid;
  padding: 1rem 0 0 0; }

.login-moreinfo {
  display: grid;
  justify-content: end;
  margin-top: 0.5rem; }
  .login-moreinfo svg {
    margin-top: -0.1rem; }

.title h3 span {
  font-size: xx-large;
  display: block; }

#login-submit-button {
  display: contents; }
