.description {
  color: #5f6369;
  font-size: 0.9em;
  display: block; }

.subsection {
  border: 2px solid #f1f1f1;
  border-radius: 5px;
  padding: 10px; }

.react-switch {
  vertical-align: middle;
  margin: 0 10px 0 0; }

.block:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle; }

.centered {
  display: inline-block; }

.basic-deployment-form-param {
  padding: 0.6rem 0 0.6rem 0; }

.deployment-form-label {
  font-weight: 600;
  margin-bottom: 0.2rem; }
  .deployment-form-label-text-param {
    display: block; }

.deployment-form-text-input {
  min-width: 30vw; }

.param-separator {
  border: 1px solid #f1f1f1; }

.slider-block {
  display: flex; }
  .slider-block .slider-input-and-unit {
    margin-left: 0.6rem; }
  .slider-block .slider-content {
    width: 30vw; }
  .slider-block .slider-input {
    width: 50%; }
