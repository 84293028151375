.app-repo-form {
  color: var(--clr-table-font-color, #666666);
  white-space: normal;
  text-align: left;
  width: 97%; }
  .app-repo-form h2 {
    margin-top: 0; }
  .app-repo-form .clr-control-container {
    width: 100%;
    padding-right: 2rem; }
    .app-repo-form .clr-control-container .clr-input {
      width: 100%; }
    .app-repo-form .clr-control-container textarea {
      width: 100%; }
  .app-repo-form .clr-form-description {
    font-size: smaller; }
    .app-repo-form .clr-form-description strong {
      font-weight: var(--clr-forms-label-font-weight, 600);
      color: var(--clr-forms-label-color, #454545); }
  .app-repo-form .clr-form-columns {
    width: 100%; }
    .app-repo-form .clr-form-columns .column-valing-center {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center; }
    .app-repo-form .clr-form-columns .clr-control-label-radio {
      font-weight: normal;
      padding: 0.2rem; }
      .app-repo-form .clr-form-columns .clr-control-label-radio input {
        margin-right: 0.2rem; }
    .app-repo-form .clr-form-columns .clr-control-label-checkbox {
      font-weight: normal;
      color: var(--clr-forms-label-color, #454545);
      font-size: 0.65rem; }
  .app-repo-form .clr-form-separator-sm {
    margin-top: 0.3rem;
    width: 100%; }
  .app-repo-form .clr-form-separator {
    margin-top: 1.2rem;
    width: 100%; }
  .app-repo-form .clr-checkbox-wrapper input[type="checkbox"] {
    opacity: 1; }
  .app-repo-form .secondary-input {
    margin-top: 0.3rem;
    padding: 0.6rem;
    background-color: var(--clr-color-neutral-100); }
