.rollback-menu {
  overflow-y: hidden; }
  .rollback-menu-text {
    color: var(--clr-p-color, black);
    text-align: center; }
  .rollback-menu-label {
    color: var(--clr-p-color, black);
    display: inline-block;
    margin-top: 0.6rem; }
  .rollback-menu .clr-select-wrapper {
    margin-left: 0.6rem; }
  .rollback-menu-buttons {
    margin-top: 0.6rem;
    text-align: center; }
    .rollback-menu-buttons cds-button {
      margin: 0 0.3rem 0 0.3rem; }
