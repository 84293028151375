.empty-catalog {
  text-align: center;
  width: 100%;
  height: calc(100vh - 6rem); }
  .empty-catalog cds-icon {
    margin-top: 2rem;
    width: 4rem;
    height: 4rem; }
  .empty-catalog cds-button {
    margin-top: 2rem; }
  .empty-catalog p {
    margin-top: 0.6rem; }
